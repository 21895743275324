import React from "react";
import { Modal } from "../components/Modal";
import { GlobalStyle } from "../style/globalStyles";
export default function requestquote() {
  return (
    <>
      <GlobalStyle />
      <main>
        {/* "Hey Zyppys quote!" */}
        <Modal />
      </main>
    </>
  );
}
