import React, { useState, useEffect } from "react";
import queryString, { parse } from "query-string";
import axios from "axios";
import Select from "react-select";
import { GoogleLogin } from "react-google-login";
import {
  Button,
  Form,
  ModalComponent,
  ModalContent,
  SelectStyles,
} from "../style/modalStyle";
import { navigate } from "gatsby";
export const Modal = () => {
  var parsed = {};
  var mobReg = /^[6-9]\d{9}$/;
  const insertURL = "https://uat.zyppys.in/insertPlaceHolderOrder";
  const vehicleTypesURL = "https://uat.zyppys.in/fetchProductNames";
  const [state, setState] = useState({});
  const [modalDisplayStyle, setModalDisplayStyle] = useState("block");
  if (typeof location !== `undefined`) {
    parsed = queryString.parse(location.search);
    parsed = JSON.parse(parsed.data);
  }
  useEffect(() => {
    if (parsed) {
      setState({
        ...state,
        pickup: parsed.pickup,
        dest: parsed.dest,
        rentalType: parsed.rentalType,
        stops: parsed.stops,
        startDate: parsed.startDate,
        startTime: parsed.startTime,
        endDate: parsed.endDate,
        endTime: parsed.endTime,
        hourlyPackage: parsed.hourlyPackage,
        carType: null,
      });
      // console.log("use effect", state);
    }
    axios.post(vehicleTypesURL, {}).then((res) => {
      // console.log(res);
    });
  }, []);
  const carType = [
    { label: "Mini/ Hatchback", value: 100090047 },
    { label: "Sedan", value: 100090048 },
    { label: "Luxury Sedan", value: 100090050 },
    { label: "SUV", value: 100090049 },
    { label: "Mini Bus", value: 100090051 },
  ];

  const cartypes = carType.map((car) => (
    <option value={car.value} key={car.value}>
      {" "}
      {car.label}{" "}
    </option>
  ));

  function format(input) {
    var date = new Date(input);
    // console.log(input, date);
    return [
      ("0" + (date.getMonth() + 1)).slice(-2),
      ("0" + date.getDate()).slice(-2),
      date.getFullYear(),
    ].join("/");
  }
  // console.log("component", state);
  const responseGoogle = (response) => {
    // console.log(response);
    setState({
      ...state,
      name: response.profileObj.givenName,
      email: response.profileObj.email,
    });
  };
  const handleChange = (e) => {
    // console.log(e);
    setState({ ...state, [e.target.name]: e.target.value });
    // console.log("inside hc", state);
  };
  const handleSubmit = (e) => {
    // e.preventDefault();
    const innsertObj = {
      bid: {
        bidAmount: null,
      },
      pricingRequestBean: {
        pickupLocation: {
          latitude: state.pickup.cityLat,
          longitude: state.pickup.cityLng,
        },
        destinationLocation: {
          latitude: state.dest.cityLat,
          longitude: state.dest.cityLng,
        },
        user: {
          name: state.name,
          mobileNo: state.mobile,
          emailId: state.email,
        },
        stops: state.stops,
        ride: {
          isPortal: true,
          pickupCoordinates: state.pickup.cityLat + ", " + state.pickup.cityLng,
          pickupLocality: state.pickup.loc || state.area,
          destinationCoordinates:
            state.dest.cityLat + ", " + state.dest.cityLng,
          destinationLocality: state.dest.loc,
          rideStartDate: state.startDate + " " + state.startTime,
          rideEndDate: state.endDate + " " + state.endTime,
          distance: null,
          categoryId: parseInt(state.carType),
          subCategoryId: null,
          accountId: null,
          couponCode: null,
          redeemedPoints: 0,
          transmissionTypeId: null,
          fuelTypeId: null,
          travelerName: state.name,
          travelerContactNo: state.mobile,
          travelerEmailId: state.email,
          measureLookupId: state.measureLookupId,
        },
      },
    };
    // console.log("quote object", state, innsertObj);
    axios
      .post(insertURL, innsertObj)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          // console.log(res.status);

          alert(
            "Thank you for requesting the quote! Please wait until we send quotations to your mail, usually it takes less than 12 hours."
          );
          if (typeof window !== `undefined`) {
            window.location.href = "https://zyppys.com";
          }
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // Get the modal
  if (typeof document !== `undefined`) {
    var modal = document.getElementById("myModal");
  }
  // When the user clicks the button, open the modal
  function Display() {
    setModalDisplayStyle("block");
  }

  // When the user clicks on <span> (x), close the modal
  function Close() {
    setModalDisplayStyle("none");
  }
  // When the user clicks anywhere outside of the modal, close it
  if (typeof window !== `undefined`) {
    window.onclick = function (event) {
      if (event.target == modal) {
        // setModalDisplayStyle("none");
      }
    };
  }
  return (
    <>
      {/* <h3>Still not satisfied with the results?</h3>
      <br />
      <a href="#" onClick={Display}>
        Get quote!
      </a> */}
      <ModalComponent id="myModal" modalDisplayStyle={modalDisplayStyle}>
        <ModalContent className="modal-content">
          {/* <span onClick={Close} style={closeStyle}>
            &times;
          </span> */}
          <h3>Quotation Request Form</h3>

          <span
            style={{
              fontSize: ".7rem",
              color: "GrayText",
            }}
          >
            Please fill all the * fields for us to contact you
          </span>
          <br />
          <br />
          <span
            style={{
              fontSize: "1rem",
              color: "coral",
            }}
          >
            {parsed.rentalType
              ? parsed.rentalType === 1
                ? "Outstation Cab"
                : parsed.rentalType === 2
                ? "Self Driven"
                : parsed.rentalType === 3
                ? "Airport Transport"
                : parsed.rentalType === 5 ||
                  parsed.rentalType === 6 ||
                  parsed.rentalType === 7
                ? "Hourly Rental"
                : "no type 1"
              : "no type 2"}
          </span>
          <br />
          <br />
          <span
            style={{
              fontSize: "0.5rem",
              float: "right",
              marginRight: "2rem",
              borderBottom: "1px solid black",
            }}
          >
            {" "}
            <a href="https://zyppys.com">Edit ride info.</a>{" "}
          </span>
          <Form width="13rem">
            <>
              <div>
                <label htmlFor="pickup">
                  {parsed.rentalType === 1 || parsed.rentalType === 3
                    ? "Pick-up"
                    : "Start"}{" "}
                  Location *
                  {/* <input
                  id="pickup"
                  name="pickup"
                  type="text"
                  placeholder={
                    parsed.pickup.city
                      ? parsed.pickup.city
                      : "pickup from state goes here"
                  }
                  onChange={handleChange}
                /> */}
                  <p>
                    {parsed.pickup ? parsed.pickup.city : "No pickup location"}
                  </p>
                </label>
                {(parsed.rentalType === 1 || parsed.rentalType === 3) && (
                  <label htmlFor="dest">
                    Destination Location *
                    {/* <input
                  id="dest"
                  name="dest"
                  type="text"
                  placeholder={
                    parsed.dest.city
                      ? parsed.dest.city
                      : "dest from state goes here"
                  }
                  onChange={handleChange}
                /> */}
                    <p>
                      {parsed.dest
                        ? parsed.dest.city
                        : "No destination location"}
                    </p>
                  </label>
                )}
                {parsed.rentalType === 5 ||
                parsed.rentalType === 6 ||
                parsed.rentalType === 7 ? (
                  <label htmlFor="hourlyPackage">
                    Hourly Package *
                    {/* <input
                  id="hourlyPackage"
                  name="hourlyPackage"
                  type="text"
                  placeholder={
                    parsed.hourlyPackage
                      ? parsed.hourlyPackage
                      : "hourlyPackage from state goes here"
                  }
                  onChange={handleChange}
                /> */}
                    <p>
                      {parsed.hourlyPackage
                        ? parsed.hourlyPackage
                        : "No location"}
                    </p>
                  </label>
                ) : (
                  ""
                )}
                {parsed.stops && parsed.rentalType === 1 ? (
                  <label htmlFor="tripType">
                    Trip Type *
                    {/* <input
                  id="hourlyPackage"
                  name="hourlyPackage"
                  type="text"
                  placeholder={
                    parsed.hourlyPackage
                      ? parsed.hourlyPackage
                      : "hourlyPackage from state goes here"
                  }
                  onChange={handleChange}
                /> */}
                    <p>
                      {parsed.rentalType === 1 && parsed.stops.length >= 3
                        ? "Two way/ Round trip"
                        : "One way trip"}
                    </p>
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label htmlFor="startDate">
                  Start Date *
                  {/* <input
                  id="startDate"
                  name="startDate"
                  type="date"
                  placeholder={
                    parsed.startDate
                      ? parsed.startDate
                      : "startDate from state goes here"
                  }
                  onChange={handleChange}
                /> */}
                  <p>{parsed.startDate ? parsed.startDate : "No start date"}</p>
                </label>
                <label htmlFor="startTime">
                  Start Time *
                  {/* <input
                  id="startTime"
                  name="startTime"
                  type="time"
                  placeholder={
                    parsed.startTime
                      ? parsed.startTime
                      : "startTime from state goes here"
                  }
                  onChange={handleChange}
                /> */}
                  <p>{parsed.startTime ? parsed.startTime : "No start time"}</p>
                </label>
                <label htmlFor="endDate">
                  End Date *
                  {/* <input
                  id="endDate"
                  name="endDate"
                  type="date"
                  placeholder={
                    parsed.endDate
                      ? parsed.endDate
                      : "endDate from state goes here"
                  }
                  onChange={handleChange}
                /> */}
                  <p>{parsed.endDate ? parsed.endDate : "no end date"}</p>
                </label>

                <label htmlFor="endTime">
                  End Time *
                  {/* <input
                  id="endTime"
                  name="endTime"
                  type="time"
                  placeholder={
                    parsed.endTime
                      ? parsed.endTime
                      : "endTime from state goes here"
                  }
                  onChange={handleChange}
                /> */}
                  <p>{parsed.endTime ? parsed.endTime : "No end time"}</p>
                </label>
              </div>
              <div style={{ display: state.name ? "none" : "block" }}>
                <GoogleLogin
                  clientId="865035061875-8cnevt3l9ar2fib2f64ne1mmvvp5ojhr.apps.googleusercontent.com"
                  buttonText="Verify with G-Mail"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={"single_host_origin"}
                  style={{ width: "100%" }}
                />
              </div>
              <div style={{ display: state.name ? "flex" : "none" }}>
                {" "}
                <label htmlFor="name">
                  Name *
                  {/* <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder={state.email ? state.email : "Enter your name"}
                    onChange={handleChange}
                  /> */}
                  <p>{state.name && state.name}</p>
                </label>
                <label htmlFor="email">
                  E-mail *
                  {/* <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder={
                      state.email ? state.email : "Enter your email address"
                    }
                    onChange={handleChange}
                  /> */}
                  <p>{state.email && state.email}</p>
                </label>{" "}
              </div>
              <div style={{ display: state.name ? "flex" : "none" }}>
                <label htmlFor="area">
                  {parsed.rentalType === 1 || 3 ? "Pick-up" : "Start"} Area *
                  <input
                    id="area"
                    name="area"
                    type="area"
                    placeholder={`Enter ${
                      parsed.rentalType === 1 || 3 ? "pick-up" : "start"
                    } area`}
                    onChange={handleChange}
                  />
                </label>
                <label htmlFor="carType">
                  Car Type *
                  <select name="carType" onChange={handleChange}>
                    {cartypes}
                  </select>
                </label>

                <label htmlFor="mobile">
                  Mobile number *
                  <input
                    id="mobile"
                    name="mobile"
                    type="text"
                    placeholder={"Enter mobile number"}
                    onChange={handleChange}
                  />
                </label>
              </div>
              {/* <label htmlFor="carType">
                Car Type *
                <label
                  style={{
                    flexDirection: "row",
                    margin: "1rem 0rem",
                    textAlign: "left",
                  }}
                >
                  <Select
                    defaultValue={carType[0]}
                    styles={SelectStyles}
                    className="basic-single"
                    width="100%"
                    height="2rem"
                    options={carType}
                    // onChange={}
                  />
                </label>
              </label> */}
            </>
          </Form>
          <Button
            style={{
              display: state.name ? "" : "none",
              backgroundColor: mobReg.exec(state.mobile)
                ? "lightseagreen"
                : "lightgray",
            }}
            onClick={mobReg.exec(state.mobile) && handleSubmit}
          >
            Request Quote
          </Button>
        </ModalContent>
      </ModalComponent>
    </>
  );
};
